var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "label-width": 90,
      "label-position": "left"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "登录账号：",
      "prop": "username"
    }
  }, [_vm._v(_vm._s(_vm.form.username))]), _c('FormItem', {
    attrs: {
      "label": "用户头像："
    }
  }, [_c('upload-pic-thumb', {
    attrs: {
      "multiple": false
    },
    model: {
      value: _vm.form.avatar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "avatar", $$v);
      },
      expression: "form.avatar"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "用户名：",
      "prop": "nickname",
      "error": _vm.error
    }
  }, [_c('Input', {
    staticStyle: {
      "width": "300px"
    },
    model: {
      value: _vm.form.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nickname", $$v);
      },
      expression: "form.nickname"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "性别："
    }
  }, [_c('dict', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "dict": "sex"
    },
    model: {
      value: _vm.form.sex,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sex", $$v);
      },
      expression: "form.sex"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "生日："
    }
  }, [_c('DatePicker', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "type": "date"
    },
    on: {
      "on-change": _vm.changeBirth
    },
    model: {
      value: _vm.form.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birth", $$v);
      },
      expression: "form.birth"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "所在省市："
    }
  }, [_c('al-cascader', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "data-type": "code",
      "level": "2"
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "街道地址：",
      "prop": "street"
    }
  }, [_c('Input', {
    staticStyle: {
      "width": "300px"
    },
    model: {
      value: _vm.form.street,
      callback: function ($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "个人简介：",
      "prop": "description"
    }
  }, [_c('Input', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "type": "textarea",
      "autosize": {
        minRows: 3,
        maxRows: 5
      },
      "placeholder": "个人简介"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "label": "所属部门："
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.departmentTitle))])]), _c('FormItem', {
    attrs: {
      "label": "用户类型："
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.typeTxt))])]), _c('FormItem', [_c('Button', {
    staticStyle: {
      "width": "100px",
      "margin-right": "5px"
    },
    attrs: {
      "type": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.saveEdit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };