import { userInfoEdit } from "@/api/index";
import uploadPicThumb from "@/views/my-components/xboot/upload-pic-thumb";
import dict from "@/views/my-components/xboot/dict";
export default {
  components: {
    uploadPicThumb,
    dict
  },
  name: "user",
  data() {
    return {
      loading: false,
      form: {
        address: []
      },
      error: ""
    };
  },
  methods: {
    init() {
      let userInfo = this.getUserInfo();
      // 转换null为""
      for (let attr in userInfo) {
        if (userInfo[attr] == null) {
          userInfo[attr] = "";
        }
      }
      this.form = userInfo;
      if (userInfo.address) {
        if (typeof userInfo.address == "string") {
          this.form.address = userInfo.address.split(",");
        } else {
          this.form.address = userInfo.address;
        }
      } else {
        this.form.address = [];
      }
      if (this.form.type == 0) {
        this.form.typeTxt = "普通用户";
      } else if (this.form.type == 1) {
        this.form.typeTxt = "管理员";
      }
    },
    changeBirth(v) {
      this.form.birth = v;
    },
    saveEdit() {
      if (!this.form.nickname) {
        this.error = "请输入用户名";
        return;
      } else {
        this.error = "";
      }
      this.loading = true;
      if (typeof this.form.birth == "object") {
        this.form.birth = this.format(this.form.birth, "yyyy-MM-dd");
      }
      delete this.form.roles;
      userInfoEdit(this.form).then(res => {
        this.loading = false;
        if (res.success) {
          this.$Message.success("保存成功");
          // 更新头像
          this.$store.commit("setNickname", this.form.nickname);
          this.$store.commit("setAvatar", this.form.avatar);
          // 需要更新用户信息
          this.$emit("on-success", true);
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};